import React, { useEffect, useState, useRef } from 'react';
import * as Dapp from '@elrondnetwork/dapp';
import { Pane, Heading, Paragraph, SelectField, TextInputField, Button, RadioGroup } from 'evergreen-ui';
import { useMediaQuery } from 'react-responsive';
import { getNfts } from '../../apiEndpoints';
import { claimSCAmount, createDrop, DropData, getDrop, getLastDropId, getOnSmartContract } from '../../transactions';
import { Transaction } from '@elrondnetwork/erdjs/out';

const DropTab = () => {
  const { address } = Dapp.useContext();
  const [pending, setPending] = useState(false);
  const [nfts, setNftsList] = useState([]);
  const [tokenIdentifier, setTokenIdentifier] = useState('');
  const [quantity, setQuantity] = useState(0);
  const [price, setPrice] = useState(0.1);
  const [maxPerTransaction, setMaxPerTransaction] = useState(10);
  const [timestamp, setTimestamp] = useState(Math.ceil((Date.now().valueOf())));
  const [drop, setDrop] = useState<DropData>();
  const sendTransaction = Dapp.useSendTransaction();

  const [options] = React.useState([
    { label: 'Oui', value: 'true' },
    { label: 'Non', value: 'false' },
  ])

  const [gettableTokenIdentifier, setGettableTokenIdentifier] = useState('');
  const [gettableNonce, setGettableNonce] = useState(0);
  const [gettableQuantity, setGettableQuantity] = useState(0);
  const [gettableSCDrop, setGettableSCDrop] = useState('true');

  const smallRes = useMediaQuery({
    query: '(max-width: 860px)',
  });

  const mounted = useRef(true);

  const issueDrop = (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (tokenIdentifier && quantity && price) {
      const selected: any = nfts.find((nft: any) => nft.identifier === tokenIdentifier);

      const tx: Transaction = createDrop({
        senderAddress: address,
        tokenIdentifier: selected.collection,
        quantity,
        nonce: selected!.nonce,
        price,
        start_time: Math.ceil(timestamp / 1000),
        max_per_transaction: maxPerTransaction
      });
      sendTransaction({
        transaction: tx,
        callbackRoute: '/dashboard',
      });
    }
  };

  const getLastDrop = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    const drop_id = await getLastDropId();
    const drop_info: DropData = await getDrop(drop_id);
    setDrop(drop_info);
  }

  const claimAmount = (e: React.SyntheticEvent) => {
    e.preventDefault();
    const tx = claimSCAmount();
    sendTransaction({
      transaction: tx,
      callbackRoute: '/dashboard',
    });
  }

  const getOnSmartContractLocal = () => {
    const tx = getOnSmartContract({
      token_identifier : gettableTokenIdentifier,
      nonce : gettableNonce,
      quantity : gettableQuantity
    }, gettableSCDrop === 'true');
    sendTransaction({
      transaction: tx,
      callbackRoute: '/dashboard',
    });
  }

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    setPending(true);
    const fetchNfts = async () => {
      const response = await fetch(getNfts(address), { signal });
      const data = await response.json();
      if (mounted.current) {
        setNftsList(data);
        setPending(false);
      }
    };
    if (address) {
      fetchNfts();
    }
    return () => {
      mounted.current = false;
    };
  }, [address]);

  return (
    <Pane marginBottom={25}>
      <Pane display="flex" flexWrap="wrap">
        <Pane
          backgroundColor="white"
          width={smallRes ? '100%' : '50%'}
          padding={30}
          elevation={1}
        >
          <Pane marginBottom={30}>
            <Heading size={600} marginBottom={5}>
              1. Drop SFT(s)
            </Heading>
            <Paragraph>
              Permet d'envoyer les SFT(s) sur le Smart Contract de vente
            </Paragraph>
          </Pane>
          <Pane marginBottom={50} maxWidth={400} width="100%">

            <SelectField
              name="tokenIdentifier"
              placeholder="exemple: TOKE-34562-01"
              label="Identifiant du Token"
              required
              description="Identifiant du Token ESDT"
              value={tokenIdentifier}
              onChange={(e: any) => setTokenIdentifier(e.target.value)}
            >
              <option value="">-</option>
              {nfts.map((nft: any) => (
                <option key={nft.identifier} value={nft.identifier}>
                  {nft.name} | {nft.identifier}
                </option>
              ))}
            </SelectField>

            <TextInputField
              name="quantity"
              placeholder="5"
              label="Quantity"
              description="Quantité du SFT"
              value={quantity}
              onChange={(e: any) => setQuantity(parseInt(e.target.value) || 0)}
            />

            <TextInputField
              name="price"
              placeholder="5"
              label="Prix"
              description="Prix en eGLD"
              value={price}
              onChange={(e: any) => setPrice(e.target.value)}
            />

            <TextInputField
              name="max_per_transaction"
              placeholder="10"
              label="Max par transaction"
              description="Maximum de SFT par transaction"
              value={maxPerTransaction}
              onChange={(e: any) => setMaxPerTransaction(parseInt(e.target.value) || 0)}
            />

            <TextInputField
              name="timestamp"
              placeholder="1641722845"
              label="Timestamp"
              description={new Date(timestamp).toLocaleDateString("fr-FR", { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }) + " " + new Date(timestamp).toLocaleTimeString()}
              value={timestamp}
              onChange={(e: any) => setTimestamp((parseInt(e.target.value) || 0) * 1000)}
            />
            <Button onClick={issueDrop}>Publier le Drop</Button>

          </Pane>
        </Pane>
        <Pane
          backgroundColor="white"
          width={smallRes ? '100%' : '50%'}
          padding={30}
          elevation={1}
        >
          <Button onClick={getLastDrop}>Get Last Drop Infos</Button>
          <Button marginLeft={10} onClick={claimAmount}>Récupérer la balance du smart contract</Button>
          <Heading marginTop={20}>Récupérer un token sur le smart contract</Heading>
          <RadioGroup
            label="Drop SC"
            size={16}
            value={gettableSCDrop}
            options={options}
            onChange={(event:any) => setGettableSCDrop(event.target.value)}
          />
          <TextInputField
            name="price"
            placeholder="TOKE-34562"
            label="Ticker"
            description="Ticker de la collection"
            value={gettableTokenIdentifier}
            onChange={(e: any) => setGettableTokenIdentifier(e.target.value)}
          />
          <TextInputField
            name="quantity"
            placeholder="1"
            label="Nonce"
            value={gettableNonce}
            onChange={(e: any) => setGettableNonce(parseInt(e.target.value) || 0)}
          />

          <TextInputField
            name="quantity"
            placeholder="5"
            label="Quantity"
            description="Quantité du SFT"
            value={gettableQuantity}
            onChange={(e: any) => setGettableQuantity(parseInt(e.target.value) || 0)}
          />
          <Button onClick={() => getOnSmartContractLocal()}>Récupérer</Button>

          {
            drop ? <Pane
              marginTop={10}>
              <TextInputField
                label="Id"
                description="Identifiant du drop"
                readOnly
                value={drop?.drop_id.toString()}
              />

              <TextInputField
                label="Token"
                description="Identifiant du token"
                readOnly
                value={drop?.tokenName + "-0" + drop?.nonce?.toString()}
              />

              <TextInputField
                label="Quantité"
                description="Quantité en vente"
                readOnly
                value={drop?.quantity?.toString()}
              />


              <TextInputField
                label="Prix"
                description="Prix de vente"
                readOnly
                value={(drop!.unit_price! / Math.pow(10, 18)).toFixed(3)}
              />

              <TextInputField
                label="Max par transaction"
                description="Maximum par transaction"
                readOnly
                value={drop?.max_per_transaction!.toString()}
              />

              <TextInputField
                label="Timestamp"
                description="Unix timestamp of drop start time"
                readOnly
                value={new Date(drop?.start_time! * 1000).toLocaleDateString("fr-FR", { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }) + " " + new Date(drop?.start_time! * 1000).toLocaleTimeString()}
              />
            </Pane> : null
          }
        </Pane>
      </Pane>
    </Pane>
  );
};

export default DropTab;
