import React, { useEffect, useState, useRef } from 'react';
import * as Dapp from '@elrondnetwork/dapp';
import { Pane, Heading, Spinner, Text, Link, Button, TextInputField, Checkbox } from 'evergreen-ui';
import { useMediaQuery } from 'react-responsive';
import { getAllNftsFromCreator } from '../../apiEndpoints';
import { getClaimableInfo, setClaimableAmount } from '../../transactions';
import { gameToken, randdToken } from '../../config';
const RewardsTab = () => {
  const { address, explorerAddress } = Dapp.useContext();
  const [nfts, setNftsList] = useState([]);
  const [pending, setPending] = useState(false);
  const sendTransaction = Dapp.useSendTransaction();
  const smallRes = useMediaQuery({
    query: '(max-width: 860px)',
  });

  const mounted = useRef(true);

  const updateNftClaimableInfo = (value: any, index: number, key : string) => {
    let items: any = [...nfts];
    let item = { ...(items[index] as any) };
    item.claimable_info[key] = value;
    items[index] = item;
    setNftsList(items);
  }


  const saveClaimableInfo = async (nft : any) => {
    const tx = await setClaimableAmount({
      tokenIdentifier : nft.ticker,
      nonce : nft.nonce,
      amount : nft.claimable_info.amount,
      countdown : nft.claimable_info.countdown,
      reward_token : nft.claimable_info.reward_token || randdToken
    });
    sendTransaction({
      transaction: tx,
      callbackRoute: '/dashboard',
    });
  }

  useEffect(() => {
   
    setPending(true);
    const fetchNfts = async () => {
      let response = await getAllNftsFromCreator();
      if (mounted.current) {
        response = await Promise.all(response.map(async (nft: any) => {
          nft.claimable_info = await getClaimableInfo(nft.ticker, nft.nonce);
          return nft;
        }));
        setNftsList(response);
        console.log(response)
        setPending(false);
      }
    };
    if (address) {
      fetchNfts();
    }
    return () => {
      mounted.current = false;
    };
  }, [address]);

  return (
    <Pane marginBottom={25}>
      <Pane marginBottom={30} marginTop={30}>
        <Heading size={700}>Gestion des récompenses :</Heading>
      </Pane>
      <Pane display="flex" flexWrap="wrap">
        {pending ? (
          <Spinner marginX="auto" marginY="auto" />
        ) : (
          nfts.map((nft: any, index: number) => (
            <Pane
              backgroundColor="white"
              width={smallRes ? 'calc(50% - 20px)' : 'calc(25% - 20px)'}
              margin={10}
              padding={20}
              elevation={1}
              key={index}
            >
              <Heading>{nft.name}</Heading>
              <Pane>
                <Text fontSize={12}>Collection: </Text>
                <Link href={`${explorerAddress}collections/${nft.collection}`}>
                  <Text
                    color="gray800"
                    fontSize={12}
                    textDecoration="underline"
                  >
                    {nft.collection}
                  </Text>
                </Link>
              </Pane>
              <Pane marginBottom={10}>
                <Text fontSize={12}>Id: </Text>
                <Link href={`${explorerAddress}nfts/${nft.identifier}`}>
                  <Text
                    color="gray800"
                    fontSize={12}
                    textDecoration="underline"
                  >
                    {nft.identifier}
                  </Text>
                </Link>
              </Pane>

              <Pane marginBottom={10}>
                <TextInputField
                  name="reward"
                  label=""
                  placeholder="0 $SPACIUM"
                  description="Récompense en $RND"
                  value={nft.claimable_info.amount || 0}
                  onChange={(e: any) => updateNftClaimableInfo(parseInt(e.target.value) || 0, index, "amount")}
                />

                <TextInputField
                  name="delai"
                  label=""
                  placeholder="0s"
                  description="Délai entre les récompenses"
                  hint="Délai en seconde"
                  value={nft.claimable_info.countdown || 0}
                  onChange={(e: any) => updateNftClaimableInfo(parseInt(e.target.value) || 0, index, "countdown")}
                />
                 <Checkbox checked={nft.claimable_info.reward_token == gameToken} 
                 onChange={(e) => {  
                   console.log(e.target.checked)
                  updateNftClaimableInfo(e.target.checked ? gameToken : randdToken, index, "reward_token")}}
                  label="Checked => Spacium"
                />

                <Button onClick={() => saveClaimableInfo(nft)}>Valider</Button>
              </Pane>

              {nft.uris?.[0] && (
                <Pane is="a">
                  <Pane
                    is="img"
                    src={nft.media[0].thumbnailUrl}
                    maxWidth="100%"
                    width="auto"
                  />
                </Pane>
              )}
            </Pane>
          ))
        )}
        {!pending && nfts.length === 0 && <Text>Aucun SFT de publier pour le moment.</Text>}
      </Pane>
    </Pane>
  );
};

export default RewardsTab;
