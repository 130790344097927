import * as Dapp from '@elrondnetwork/dapp';

export const walletConnectBridge: string =
  process.env.REACT_APP_WALLET_CONNECT_BRIDGE || '';
export const walletConnectDeepLink: string =
  process.env.REACT_APP_WALLET_CONNECT_DEEP_LINK || '';

export const network: Dapp.NetworkType = {
  id: process.env.REACT_APP_NETWORK_ID || '',
  name: process.env.REACT_APP_NETWORK_NAME || '',
  egldLabel: process.env.REACT_APP_NETWORK_EGLD_LABEL || '',
  walletAddress: process.env.REACT_APP_NETWORK_WALLET_ADDRESS,
  apiAddress: process.env.REACT_APP_NETWORK_API_ADDRESS,
  gatewayAddress: process.env.REACT_APP_NETWORK_GATEWAY_ADDRESS,
  explorerAddress: process.env.REACT_APP_NETWORK_EXPLORER_ADDRESS,
};


export const smartContract = process.env.REACT_APP_SMART_CONTRACT;
export const dropSmartContractAdress = process.env.REACT_APP_DROP_SMART_CONTRACT;
export const storeSmartContractAddress = process.env.REACT_APP_STORE_SMART_CONTRACT;
export const creatorAddress = process.env.REACT_APP_CREATOR_ADDRESS;
export const gameToken = process.env.REACT_APP_GAME_TOKEN;
export const randdToken = process.env.REACT_APP_RANDD_TOKEN;