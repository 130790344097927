import { creatorAddress, network } from './config';

export const getTransactionByHash = (txHash: string) =>
  `${network.apiAddress}/transactions/${txHash}`;

export const getTransactions = (
  senderAddress: string,
  from: number = 0,
  size: number = 5
) =>
  `${network.apiAddress}/transactions?sender=${senderAddress}&receiver=${senderAddress}&condition=should&from=${from}&size=${size}`;

export const getNfts = (walletAddress: string) =>
  `${network.apiAddress}/accounts/${walletAddress}/nfts?type=SemiFungibleESDT`;

export const nftCollections = (walletAddress: string) =>
  `${network.apiAddress}/accounts/${walletAddress}/collections`;

export const getAllNftsFromCreator = async () : Promise<any> => {
  const res = await fetch(`${network.apiAddress}/nfts?creator=${creatorAddress}&size=200`);
  return await res.json();
} 

export const getAllTokens = async () : Promise<any> => {
  const res = await fetch(`${network.apiAddress}/accounts/${creatorAddress}/tokens`);
  return await res.json();
}