import React, { useEffect, useState, useRef } from 'react';
import * as Dapp from '@elrondnetwork/dapp';
import { Pane, Heading, Paragraph, TextInputField, Button, SelectField, DeleteIcon } from 'evergreen-ui';
import { useMediaQuery } from 'react-responsive';
import { createChest, CreateChestData, createSwapTransaction, freezeECCUFromAccount, sendESDTToken, setKeyTokenIdentifier, } from '../../transactions';
import { gameToken } from '../../config';
import { getAllNftsFromCreator, getAllTokens, getNfts } from '../../apiEndpoints';

const SwapTab = () => {
  const { address } = Dapp.useContext();
  const [pending, setPending] = useState(false);
  const [formIdentifier, setFormIdentifier] = useState("");
  const [formQuantity, setFormQuantity] = useState(1);

  const [rewardIdentifier, setRewardIdentifier] = useState("");
  const [rewardQuantity, setRewardQuantity] = useState(1);

  const [rewards, setRewards] = useState([] as any[]);
  const [payments, setPayments] = useState([] as any[]);

  const [start_time, setStartTime] = useState(Date.now().valueOf())
  const [nfts, setNftsList] = useState([]);

  const [epicIdentifier, setEpicIdentifier] = useState("");
  const [quantity, setQuantity] = useState(0);
  const [probality, setProbability] = useState(0);
  const [max_win, setMaxWin] = useState(0);
  const sendTransaction = Dapp.useSendTransaction();
  const smallRes = useMediaQuery({
    query: '(max-width: 860px)',
  });



  const addPayment = () => {
    const selected: any = nfts.find((nft: any) => nft.identifier === formIdentifier);
    const el = { ticker: selected!.ticker, nonce: selected.nonce || 0, quantity: formQuantity };
    setPayments(oldArray => [...oldArray, el]);
    setFormIdentifier("");
    setFormQuantity(1);
  }

  const addReward = () => {
    const selected: any = nfts.find((nft: any) => nft.identifier === rewardIdentifier);
    const el = { ticker: selected!.ticker, nonce: selected.nonce, quantity: rewardQuantity };
    setRewards(oldArray => [...oldArray, el]);
    setRewardIdentifier("");
    setRewardQuantity(1);
  }

  const createSwap = () => {
    const tx = createSwapTransaction(address,Math.ceil(start_time/1000), payments, rewards);
    sendTransaction({
      transaction: tx,
      callbackRoute: '/dashboard',
    });
  }

  const deletePaymentIndex = (index : number) => {
    let new_payment = [...payments];
    new_payment.splice(index,1);
    setPayments(new_payment);
  }

  const deleteRewardIndex = (index : number) => {
    let new_rewards = [...rewards];
    new_rewards.splice(index,1);
    setRewards(new_rewards);
  }

  const mounted = useRef(true);


  useEffect(() => {
    setPending(true);
    const fetchNfts = async () => {
      const response : any = await getAllNftsFromCreator();
      const tokens : any = await getAllTokens();
      console.log(tokens)
      if (mounted.current) {
        setNftsList([...response, ...tokens] as any);
        setPending(false);
      }
    };
    if (address) {
      fetchNfts();
    }
    return () => {
      mounted.current = false;
    };
  }, [address]);

  return (
    <Pane marginBottom={25}>
      <Pane display="flex" flexWrap="wrap">
        <Pane
          backgroundColor="white"
          width={smallRes ? '100%' : '50%'}
          padding={30}
          elevation={1}
        >
          <Pane marginBottom={30}>
            <Heading size={600} marginBottom={5}>
              Créer un swap
            </Heading>
            <Paragraph>
              Permet de créer un échange de token multiples
            </Paragraph>
          </Pane>
          <Pane marginBottom={50} maxWidth={400} width="100%">
            <Heading size={500} marginBottom={30}>Payments</Heading>

            <SelectField
              name="tokenIdentifier"
              placeholder="exemple: TOKE-34562-01"
              label="Identifiant "
              required
              description="Identifiant du SFT Epic"
              value={formIdentifier}
              onChange={(e: any) => setFormIdentifier(e.target.value)}
            >
              <option value="">-</option>
              {nfts.map((nft: any) => (
                <option key={nft.identifier} value={nft.identifier}>
                  {nft.name} | {nft.identifier}
                </option>
              ))}
            </SelectField>

            <TextInputField
              name="quantity"
              placeholder="1"
              label="Quantity"
              value={formQuantity}
              onChange={(e: any) => setFormQuantity(parseInt(e.target.value) || 0)}
            />
            <Button onClick={addPayment}>Enregister ce payment</Button>
            {payments.map((el,index) =>
              <Paragraph key="el.identifier">- {el.ticker + " | Quantity : " + el.quantity + " | Nonce :" + el.nonce} <DeleteIcon onClick={() => deletePaymentIndex(index)}/> </Paragraph>
            )}
            <Heading size={500} marginTop={20}>Reward</Heading>


            <SelectField
              name="tokenIdentifier"
              placeholder="exemple: TOKE-34562-01"
              label="Identifiant "
              required
              description="Identifiant du SFT Epic"
              value={rewardIdentifier}
              onChange={(e: any) => setRewardIdentifier(e.target.value)}
            >
              <option value="">-</option>
              {nfts.map((nft: any) => (
                <option key={nft.identifier} value={nft.identifier}>
                  {nft.name} | {nft.identifier}
                </option>
              ))}
            </SelectField>

            <TextInputField
              name="quantity"
              placeholder="1"
              label="Quantity"
              value={rewardQuantity}
              onChange={(e: any) => setRewardQuantity(parseInt(e.target.value) || 0)}
            />
            <Button onClick={addReward}>Enregister cette reward</Button>
            {rewards.map((el, index) =>
              <Paragraph key="el.identifier">- {el.ticker + " | Quantity : " + el.quantity + " | Nonce :" + el.nonce} <DeleteIcon onClick={() => deleteRewardIndex(index)}/> </Paragraph>
            )}
            <Paragraph></Paragraph>
            <TextInputField
              name="timestamp"
              placeholder="1"
              description={new Date(start_time).toLocaleDateString("fr-FR", { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }) + " " + new Date(start_time).toLocaleTimeString()}
              label="Timestamp"
              value={start_time}
              onChange={(e: any) => setStartTime(parseInt(e.target.value) * 1000 || 0)}
            />
            <Button marginTop={40} appearance="primary" onClick={createSwap}>Signer la transaction</Button>
          </Pane>
        </Pane>
        <Pane
          backgroundColor="white"
          width={smallRes ? '100%' : '50%'}
          padding={30}
          elevation={1}
        >
          <Pane marginBottom={30}>
            <Heading size={600} marginBottom={5}>
              Création de coffre épique
            </Heading>
            <Paragraph>
              Permet de mettre un coffre epic en ligne sur le store
            </Paragraph>
          </Pane>
          <Pane marginBottom={50} maxWidth={400} width="100%">

            <SelectField
              name="tokenIdentifier"
              placeholder="exemple: TOKE-34562-01"
              label="Identifiant de l'épique"
              required
              description="Identifiant du SFT Epic"
              value={epicIdentifier}
              onChange={(e: any) => setEpicIdentifier(e.target.value)}
            >
              <option value="">-</option>
              {nfts.map((nft: any) => (
                <option key={nft.identifier} value={nft.identifier}>
                  {nft.name} | {nft.identifier}
                </option>
              ))}
            </SelectField>

            <TextInputField
              name="quantity"
              placeholder="5"
              label="Quantity"
              description="Quantité à envoyer"
              value={quantity}
              onChange={(e: any) => setQuantity(parseInt(e.target.value) || 0)}
            />



            <TextInputField
              name="quantity"
              placeholder="5"
              label="Echelle de probabilité"
              description="1000 -> 0.1% ||  100 -> 1% de chance de l'avoir"
              value={probality}
              onChange={(e: any) => setProbability(parseInt(e.target.value) || 0)}
            />

            <TextInputField
              name="quantity"
              placeholder="5"
              label="Maximum par wallet"
              description="Nombre de fois que l'épique peut être obtenu"
              value={max_win}
              onChange={(e: any) => setMaxWin(parseInt(e.target.value) || 0)}
            />

            {/* <Button onClick={createNewEpicChest}>Créer le coffre</Button> */}
          </Pane>
        </Pane>
      </Pane>
    </Pane>
  );
};

export default SwapTab;
