import React, { useState, useEffect } from 'react';
import {
  Pane,
  Heading,
  Paragraph,
  TextInputField,
  Button,
  SelectField,
  FilePicker,
  FormField
} from 'evergreen-ui';
import { useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { nftCollections } from '../../apiEndpoints';
import { issueSft, createSFT } from '../../transactions';
import * as Dapp from '@elrondnetwork/dapp';
import axios from 'axios';
const CreateNftTab = () => {
  const location = useLocation();
  const [collections, setCollections] = useState<string[]>([]);
  const { address } = Dapp.useContext();
  const sendTransaction = Dapp.useSendTransaction();
  const [ipfsImageUri, setIpfsImage] = useState('');
  const [tokenName, setName] = useState('');
  const [tokenTicker, setTicker] = useState('');
  const [tokenIdentifier, setTokenIdentifier] = useState('');
  const [attributes, setAttributes] = useState('');
  const [nftName, setNftName] = useState('');
  const [quantity, setQuantity] = useState('');
  const smallRes = useMediaQuery({
    query: '(max-width: 860px)',
  });


  const issueNFTToken = (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (tokenName && tokenTicker) {
      const tx = issueSft({
        tokenName,
        tokenTicker,
      });
      sendTransaction({
        transaction: tx,
        callbackRoute: '/dashboard',
      });
    }
  };

  const uploadImage = async (files: FileList) => {
    let formData = new FormData();
    console.log(files);
    formData.append("file", files[0]);
    let response = await axios.post("https://api.nft.storage/upload", files[0], {
      headers: {
        Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJkaWQ6ZXRocjoweGQ3ZTU4RjI0NWNjMEI1Njc0NzUyQjc4M2U3OTJkRjdhQTkzQzAyZDUiLCJpc3MiOiJuZnQtc3RvcmFnZSIsImlhdCI6MTYzODU0NzM2MDA4NCwibmFtZSI6Im5mdF90b29scyJ9.57bzH41y2orXWabM7agLgbuDSR7WJjcc8rAKiaQ8XKU`,
        Accept: "application/json"
      }
    });
    const cid = response.data.value.cid;
    setIpfsImage("https://" + cid + ".ipfs.dweb.link");
    return cid;
  }

  useEffect(() => {
    const fetchFn = async () => {
      const response = await fetch(nftCollections(address));
      const data = await response.json();
      setCollections(data.map((item: any) => item.collection));
    };
    fetchFn();
  }, [location]);

  const createNFTToken = (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (address && ipfsImageUri && tokenIdentifier && nftName) {
      const tx = createSFT({
        senderAddress: address,
        tokenIdentifier,
        nftName,
        uri: ipfsImageUri,
        attributes,
        royalties: "0",
        quantity
      });
      sendTransaction({
        transaction: tx,
        callbackRoute: '/dashboard',
      });
    }
  };

  return (
    <Pane
      display="flex"
      marginBottom={80}
      flexDirection={smallRes ? 'column' : 'row'}
    >
      <Pane
        width={smallRes ? '100%' : '50%'}
        backgroundColor="white"
        marginRight={smallRes ? 0 : 10}
        padding={30}
        elevation={1}
      >
        <Pane marginBottom={30}>
          <Heading size={600} marginBottom={5}>
            1. Créer une collection de NFT
          </Heading>
          <Paragraph>
            Si la collection existe déjà, merci d'utiliser le formulaire de droite
          </Paragraph>
        </Pane>
        <Pane marginBottom={50} maxWidth={400} width="100%">
          <TextInputField
            name="name"
            placeholder="exemple: Collection 1"
            label="Nom"
            required
            description="Nom de la collection"
            value={tokenName}
            hint="Min 3 - 20 caractères alphanumerique"
            onChange={(e: any) => setName(e.target.value)}
          />
          <TextInputField
            name="ticker"
            placeholder="exemple: COLLECT1"
            label="Identifiant"
            required
            description="Identifiant de la collection"
            value={tokenTicker}
            hint="Min 3 - 10 caaractères alphanumerique"
            onChange={(e: any) => setTicker(e.target.value.toUpperCase())}
          />
          <Button onClick={issueNFTToken}>Publier la collection NFT</Button>
        </Pane>
      </Pane>
      <Pane
        width={smallRes ? '100%' : '50%'}
        backgroundColor="white"
        marginLeft={smallRes ? 0 : 10}
        padding={30}
        elevation={1}
      >
        <Pane marginBottom={30}>
          <Heading size={600} marginBottom={5}>
            2. Publier un NFT
          </Heading>
          <Paragraph>
            Pour publier un NFT sa collection doit auparavant exister, sinon vous pouvez créer une collection dans le formulaire de gauche
          </Paragraph>
        </Pane>
        {/* TODO: add royalties */}
        <Pane marginBottom={50} maxWidth={400} width="100%">
          <SelectField
            name="tokenIdentifier"
            placeholder="exemple: TOKE-34562"
            label="Identifiant de la collection"
            required
            description="Identifiant de la collection"
            value={tokenIdentifier}
            hint="Min 3 - 10 caractères alphanumerique"
            onChange={(e: any) => setTokenIdentifier(e.target.value)}
          >
            <option value="">-</option>
            {collections.map((item) => (
              <option key={item} value={item}>
                {item}
              </option>
            ))}
          </SelectField>

          <TextInputField
            name="nftName"
            placeholder="exemple: MonNFT"
            label="Nom du NFT"
            required
            description="Nom du NFT"
            value={nftName}
            hint="Caractères alphanumerique uniquement"
            onChange={(e: any) => setNftName(e.target.value)}
          />

          <TextInputField
            name="attributes"
            placeholder="cle1:valeur1;cle2:valeur2"
            label="Attributs"
            description="Listes des attributs du NFT, séparés par des ;"
            value={attributes}
            hint="Caractères alphanumerique uniquement"
            onChange={(e: any) => setAttributes(e.target.value)}
          />

          <TextInputField
            name="quantity"
            placeholder="5"
            label="Quantity"
            description="Quantité du SFT"
            value={quantity}
            onChange={(e: any) => setQuantity(e.target.value)}
          />

          {/* <TextInputField
            name="ipfsImageUri"
            placeholder="example: ipfs://{CID} or https://ipfs.io/ipfs/{CID}"
            label="Image URL"
            required
            description="Name of your NFT"
            value={ipfsImageUri}
            hint="The best would be to use decentralized storage, like IPFS. Nice options are nft.storage and web3.storage. It can be ipfs://{cid} or any IPFS gateway URL."
            onChange={(e: any) => setIpfsImage(e.target.value)}
          /> */}
          <FormField label="Image" description="Name of your NFT">
            <FilePicker accept="image/*" onChange={(files) => uploadImage(files)}></FilePicker>
          </FormField>
        </Pane>
        <Pane display="flex" justifyContent="center" marginBottom={20}>
          <Pane maxWidth={300} textAlign="center">
            <Pane is="img" src={ipfsImageUri} alt="" maxWidth="100%" />
          </Pane>
        </Pane>
        <Button
          onClick={createNFTToken}
          disabled={Boolean(
            !address || !ipfsImageUri || !tokenIdentifier || !nftName
          )}
        >
          Publier le NFT
        </Button>
      </Pane>
    </Pane>
  );
};

export default CreateNftTab;
