import Dashboard from './components/Dashboard/Dashboard';

export const routes = [
  {
    path: '/',
    component: Dashboard,
    authenticatedRoute: true
  },
  // {
  //   path: '/dashboard',
  //   component: Home,
  //   authenticatedRoute: true,
  // },
];
