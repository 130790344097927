import React, { useEffect, useState, useRef } from 'react';
import * as Dapp from '@elrondnetwork/dapp';
import { Pane, Heading, Paragraph, TextInputField, Button } from 'evergreen-ui';
import { useMediaQuery } from 'react-responsive';
import { freezeECCUFromAccount, sendESDTToken, setRNDToken, } from '../../transactions';
import { gameToken, randdToken } from '../../config';

const TokenTab = () => {
  const { address } = Dapp.useContext();
  const [pending, setPending] = useState(false);
  const [quantity, setQuantity] = useState(0);
  const sendTransaction = Dapp.useSendTransaction();
  const smallRes = useMediaQuery({
    query: '(max-width: 860px)',
  });

  const [freezeAddress, setFreezeAddress] = useState("");



  const mounted = useRef(true);

  const refillToken = (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (quantity > 0) {
      const tx = sendESDTToken(quantity)
      sendTransaction({
        transaction: tx,
        callbackRoute: '/dashboard',
      });
    }
  }

  const refillRNDToken = (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (quantity > 0) {
      const tx = setRNDToken(quantity)
      sendTransaction({
        transaction: tx,
        callbackRoute: '/dashboard',
      });
    }
  }


  const freezeECCUAddress = () => {
    if(freezeAddress){
      const tx = freezeECCUFromAccount(freezeAddress);
      sendTransaction({
        transaction: tx,
        callbackRoute: '/dashboard',
      });
    }
  }



  useEffect(() => {
    setPending(true);

    return () => {
      mounted.current = false;
    };
  }, [address]);

  return (
    <Pane marginBottom={25}>
      <Pane display="flex" flexWrap="wrap">
        <Pane
          backgroundColor="white"
          width={smallRes ? '100%' : '50%'}
          padding={30}
          elevation={1}
        >
          <Pane marginBottom={30}>
            <Heading size={600} marginBottom={5}>
              1. Recharger le smart contract
            </Heading>
            <Paragraph>
              Permet de recharger en smart contract en {"$" + gameToken}
            </Paragraph>
          </Pane>
          <Pane marginBottom={50} maxWidth={400} width="100%">


            <TextInputField
              name="quantity"
              placeholder="5"
              label="Quantity"
              description="Quantité à envoyer"
              value={quantity}
              onChange={(e: any) => setQuantity(parseInt(e.target.value) || 0)}
            />

            <Button onClick={refillToken}>Envoyer les jetons</Button>

          </Pane>

          <Pane marginBottom={30}>
            <Heading size={600} marginBottom={5}>
              1. Recharger le smart contract en $RND
            </Heading>
            <Paragraph>
              Permet de recharger en smart contract en {"$" + randdToken} 
            </Paragraph>
          </Pane>
          <Pane marginBottom={50} maxWidth={400} width="100%">


            <TextInputField
              name="quantity"
              placeholder="5"
              label="Quantity"
              description="Quantité à envoyer"
              value={quantity}
              onChange={(e: any) => setQuantity(parseInt(e.target.value) || 0)}
            />

            <Button onClick={refillRNDToken}>Envoyer les jetons</Button>

          </Pane>
        </Pane>
        <Pane
          backgroundColor="white"
          width={smallRes ? '100%' : '50%'}
          padding={30}
          elevation={1}
        >
          <Pane marginBottom={30}>
            <Heading size={600} marginBottom={5}>
              1. Freeze Account
            </Heading>
            <Paragraph>
              Permet de bloquer toutes les transactions d'un compte
            </Paragraph>
          </Pane>
          <Pane marginBottom={50} maxWidth={400} width="100%">

            <TextInputField
              name="quantity"
              placeholder="erd1..."
              label="Adresse à freeze"
              value={freezeAddress}
              onChange={(e: any) => setFreezeAddress(e.target.value)}
            />
           
            <Button onClick={freezeECCUAddress}>Freeze Account</Button>
          </Pane>
        </Pane>
      </Pane>
    </Pane>
  );
};

export default TokenTab;
