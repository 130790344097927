import {
  Transaction,
  GasLimit,
  Address,
  TransactionPayload,
  Balance,
  SmartContract,
  ContractFunction,
  ProxyProvider,
  TokenIdentifierValue,
  U64Value,
  BigUIntValue,
  AddressValue,
  BytesValue,
} from '@elrondnetwork/erdjs';
import { dropSmartContractAdress, gameToken, network, randdToken, smartContract, storeSmartContractAddress } from './config';
import BigNumber from "bignumber.js";
import { base64ToNumber, base64ToString } from './utils';
export interface IssueSFTData {
  tokenName: string;
  tokenTicker: string;
}

export interface AssignRolesData {
  tokenIdentifier: string;
  senderAddress: string;
}

export interface DropData {
  tokenName: string | null;
  nonce: number | null;
  quantity: number | null;
  unit_price: number | null;
  start_time: number | null;
  drop_id: number;
  max_per_transaction: number | null;
}

export interface CreateSFTData {
  senderAddress: string;
  tokenIdentifier: string;
  nftName: string;
  uri: string;
  attributes?: string;
  quantity: string;
  royalties: string
}
export interface BurnESDTData {
  senderAddress: string;
  quantity: number;
  tokenIdentifier: string;
  nonce: number;
}
export interface CreateDropData {
  senderAddress: string;
  tokenIdentifier: string;
  nonce: number;
  quantity: number;
  price: number;
  start_time: number;
  max_per_transaction: number;
}

export interface ClaimableInfo {
  tokenIdentifier: string;
  nonce: number;
  amount: number | null;
  countdown: number | null;
  reward_token : string | null;
}

export interface AddToStoreData {
  senderAddress: string;
  quantity: number;
  token_identifier: string;
  nonce: number;
  max_per_wallet: number;
  price: number;
}


export interface CreateChestData {
  token_identifier: string;
  nonce: number;
  amount: number;
  probability_range: number;
  max_win_per_user: number;
  senderAddress: string;
}

export const issueSft = (data: IssueSFTData) => {

  let payload = TransactionPayload.contractCall()
    .setFunction(new ContractFunction("issueSemiFungible"))
    //  .setFunction(new ContractFunction("issue"))
    .addArg(BytesValue.fromUTF8(data.tokenName))
    .addArg(BytesValue.fromUTF8(data.tokenTicker))
    // .addArg(new BigUIntValue(new BigNumber(30000000)))
    // .addArg(new U64Value(new BigNumber(0)))
    .addArg(BytesValue.fromUTF8("canFreeze")).addArg(BytesValue.fromUTF8("true"))
    .addArg(BytesValue.fromUTF8("canWipe")).addArg(BytesValue.fromUTF8("true"))
    .addArg(BytesValue.fromUTF8("canPause")).addArg(BytesValue.fromUTF8("true"))
    .addArg(BytesValue.fromUTF8("canTransferNFTCreateRole")).addArg(BytesValue.fromUTF8("true"))
    .addArg(BytesValue.fromUTF8("canChangeOwner")).addArg(BytesValue.fromUTF8("true"))
    .addArg(BytesValue.fromUTF8("canUpgrade")).addArg(BytesValue.fromUTF8("true"))
    .addArg(BytesValue.fromUTF8("canAddSpecialRoles")).addArg(BytesValue.fromUTF8("true"))
    .build()
    .valueOf().toString()

  return new Transaction({
    receiver: new Address(smartContract),
    value: Balance.egld('0.05'),
    data: new TransactionPayload(payload),
    gasLimit: new GasLimit(60000000),
  });
}


export const assignRoles = (data: AssignRolesData): Transaction => {
  let payload = TransactionPayload.contractCall()
    .setFunction(new ContractFunction("setSpecialRole"))
    .addArg(new TokenIdentifierValue(Buffer.from(data.tokenIdentifier, "utf-8")))
    .addArg(new AddressValue(new Address(data.senderAddress)))
    .addArg(BytesValue.fromUTF8("ESDTRoleNFTCreate"))
    .addArg(BytesValue.fromUTF8("ESDTRoleNFTAddQuantity"))
    .addArg(BytesValue.fromUTF8("ESDTRoleNFTBurn"))
    .build()
    .valueOf().toString();


  return new Transaction({
    receiver: new Address(smartContract),
    value: Balance.egld('0'),
    data: new TransactionPayload(payload),
    gasLimit: new GasLimit(60000000),
  });
}

export const createSFT = (data: CreateSFTData) => {
  let payload = TransactionPayload.contractCall()
    .setFunction(new ContractFunction("ESDTNFTCreate"))
    .addArg(new TokenIdentifierValue(Buffer.from(data.tokenIdentifier, "utf-8")))
    .addArg(new BigUIntValue(new BigNumber(data.quantity)))
    .addArg(BytesValue.fromUTF8(data.nftName))
    .addArg(new BigUIntValue(new BigNumber(500)))
    .addArg(new U64Value(new BigNumber(0)))
    .addArg(BytesValue.fromUTF8(data.attributes || ''))
    .addArg(BytesValue.fromUTF8(data.uri))
    .build()
    .valueOf().toString()

  return new Transaction({
    receiver: new Address(data.senderAddress),
    value: Balance.egld('0'),
    gasLimit: new GasLimit(60000000),
    data: new TransactionPayload(payload)
  });
}


export const burnToken = (data: BurnESDTData): Transaction => {
  let payload = TransactionPayload.contractCall()
    .setFunction(new ContractFunction("ESDTNFTBurn"))
    .addArg(new TokenIdentifierValue(Buffer.from(data.tokenIdentifier, "utf-8")))
    .addArg(new U64Value(new BigNumber(data.nonce)))
    .addArg(new BigUIntValue(new BigNumber(data.quantity)))
    .build()
    .valueOf().toString()
  console.log(payload)
  return new Transaction({
    receiver: new Address(data.senderAddress),
    value: Balance.egld('0'),
    gasLimit: new GasLimit(10000000),
    data: new TransactionPayload(payload)
  });
}

export const createDrop = (data: CreateDropData): Transaction => {

  let payload = TransactionPayload.contractCall()
    .setFunction(new ContractFunction("ESDTNFTTransfer"))
    .addArg(new TokenIdentifierValue(Buffer.from(data.tokenIdentifier)))
    .addArg(new U64Value(new BigNumber(data.nonce)),)
    .addArg(new BigUIntValue(new BigNumber(data.quantity)),)
    .addArg(new AddressValue(new Address(dropSmartContractAdress)))
    .addArg(BytesValue.fromUTF8("createDrop"))
    .addArg(new BigUIntValue(new BigNumber(data.price * Math.pow(10, 18))))
    .addArg(new U64Value(new BigNumber(data.start_time)))
    .addArg(new U64Value(new BigNumber(data.max_per_transaction)))
    .build()
    .valueOf().toString()

  return new Transaction({
    receiver: new Address(data.senderAddress),
    value: Balance.egld('0'),
    gasLimit: new GasLimit(10000000),
    data: new TransactionPayload(payload)
  });

  // return contract.call({
  //   func: new ContractFunction("ESDTNFTTransfer"),
  //   gasLimit: new GasLimit(60000000),
  //   args: [
  //     .addArg(new TokenIdentifierValue(Buffer.from(data.tokenIdentifier)),
  //     .addArg(new U64Value(new BigNumber(data.nonce)),
  //     .addArg(new BigUIntValue(new BigNumber(data.quantity)),
  //     .addArg(new AddressValue(new Address(dropSmartContractAdress)),
  //     .addArg(BytesValue.fromUTF8("createDrop"),
  //     .addArg(new BigUIntValue(new BigNumber(data.price * Math.pow(10, 18)))
  //   ]
  // })
}


export const getLastDropId = async (): Promise<number> => {
  const provider = new ProxyProvider(network.gatewayAddress as string, {
    timeout: 5000,
  });
  const contract = new SmartContract({
    address: new Address(dropSmartContractAdress)
  });

  const res = await contract.runQuery(provider, {
    func: new ContractFunction("getLastDropId"),
    args: [],
  })

  let drop_id: number = parseInt(Buffer.from(res.returnData[0], 'base64').toString('hex'), 16);
  return drop_id
}

export const getDrop = async (drop_id: number): Promise<DropData> => {
  const provider = new ProxyProvider(network.gatewayAddress as string, {
    timeout: 5000,
  });
  const contract = new SmartContract({
    address: new Address(dropSmartContractAdress)
  });

  const res = await contract.runQuery(provider, {
    func: new ContractFunction("getDropToken"),
    args: [new U64Value(new BigNumber(drop_id))],
  })

  return {
    tokenName: base64ToString(res.returnData[0]),
    nonce: base64ToNumber(res.returnData[1]),
    quantity: base64ToNumber(res.returnData[2]),
    unit_price: base64ToNumber(res.returnData[3]),
    drop_id,
    start_time: base64ToNumber(res.returnData[4]),
    max_per_transaction: base64ToNumber(res.returnData[5])
  }
}


export const claimSCAmount = (): Transaction => {
  const contract = new SmartContract({
    address: new Address(dropSmartContractAdress)
  });

  return contract.call({
    func: new ContractFunction("claimBalance"),
    args: [],
    gasLimit: new GasLimit(60000000)
  })
}

export const sendESDTToken = (amount: number) => {

  let payload = TransactionPayload.contractCall()
    .setFunction(new ContractFunction("ESDTTransfer"))
    .addArg(new TokenIdentifierValue(Buffer.from(gameToken!)))
    .addArg(new BigUIntValue(new BigNumber(amount)))
    .addArg(BytesValue.fromUTF8("addToBalance"))
    .build()
    .valueOf().toString()

  return new Transaction({
    receiver: new Address(storeSmartContractAddress),
    value: Balance.egld('0'),
    gasLimit: new GasLimit(10000000),
    data: new TransactionPayload(payload)
  });
}



export const setRNDToken = (amount: number) => {

  let payload = TransactionPayload.contractCall()
    .setFunction(new ContractFunction("ESDTTransfer"))
    .addArg(new TokenIdentifierValue(Buffer.from(randdToken!)))
    .addArg(new BigUIntValue(new BigNumber(amount)))
    .addArg(BytesValue.fromUTF8("refillRandToken"))
    .build()
    .valueOf().toString()

  return new Transaction({
    receiver: new Address(storeSmartContractAddress),
    value: Balance.egld('0'),
    gasLimit: new GasLimit(10000000),
    data: new TransactionPayload(payload)
  });
}

export const getClaimableInfo = async (ticker: string, nonce: number): Promise<ClaimableInfo> => {
  const provider = new ProxyProvider(network.gatewayAddress as string, {
    timeout: 5000,
  });
  const contract = new SmartContract({
    address: new Address(storeSmartContractAddress)
  });

  const res = await contract.runQuery(provider, {
    func: new ContractFunction("getRewardableAmount"),
    args: [
      new TokenIdentifierValue(Buffer.from(ticker)),
      new U64Value(new BigNumber(nonce))
    ],
  })
  return {
    tokenIdentifier: ticker,
    nonce,
    amount: base64ToNumber(res.returnData[0]),
    countdown: base64ToNumber(res.returnData[1]),
    reward_token : base64ToString(res.returnData[2])
  }
}

export const setClaimableAmount = async (data: ClaimableInfo) => {
  let payload = TransactionPayload.contractCall()
    .setFunction(new ContractFunction("setClaimableAmount"))
    .addArg(new TokenIdentifierValue(Buffer.from(data.tokenIdentifier)))
    .addArg(new U64Value(new BigNumber(data.nonce)))
    .addArg(new BigUIntValue(new BigNumber(data.amount!)))
    .addArg(new U64Value(new BigNumber(data.countdown!)))
    .addArg(new TokenIdentifierValue(Buffer.from(data.reward_token!))) 
    .build()
    .valueOf().toString()

  return new Transaction({
    receiver: new Address(storeSmartContractAddress),
    value: Balance.egld('0'),
    gasLimit: new GasLimit(10000000),
    data: new TransactionPayload(payload)
  });
}


export const addToStore = (data: AddToStoreData) => {
  let payload = TransactionPayload.contractCall()
    .setFunction(new ContractFunction("ESDTNFTTransfer"))
    .addArg(new TokenIdentifierValue(Buffer.from(data.token_identifier)))
    .addArg(new U64Value(new BigNumber(data.nonce)),)
    .addArg(new BigUIntValue(new BigNumber(data.quantity)),)
    .addArg(new AddressValue(new Address(storeSmartContractAddress)))
    .addArg(BytesValue.fromUTF8("addToStore"))
    .addArg(new BigUIntValue(new BigNumber(data.price!)))
    .addArg(new U64Value(new BigNumber(data.max_per_wallet!)))
    .build()
    .valueOf().toString()

  return new Transaction({
    receiver: new Address(data.senderAddress),
    value: Balance.egld('0'),
    gasLimit: new GasLimit(10000000),
    data: new TransactionPayload(payload)
  });

}

export interface GetOnSmartContractData {
  token_identifier: string;
  nonce: number;
  quantity: number;
}

export const getOnSmartContract = (data: GetOnSmartContractData, drop_sc: boolean) => {
  let payload = TransactionPayload.contractCall()
    .setFunction(new ContractFunction("claimESDTBalance"))
    .addArg(new TokenIdentifierValue(Buffer.from(data.token_identifier)))
    .addArg(new U64Value(new BigNumber(data.nonce)))
    .addArg(new BigUIntValue(new BigNumber(data.quantity!)))
    .build()
    .valueOf().toString()

  return new Transaction({
    receiver: new Address(drop_sc ? dropSmartContractAdress : storeSmartContractAddress),
    value: Balance.egld('0'),
    gasLimit: new GasLimit(10000000),
    data: new TransactionPayload(payload)
  });
}



export const freezeECCUFromAccount = (address: string) => {
  let payload = TransactionPayload.contractCall()
    .setFunction(new ContractFunction("freeze"))
    .addArg(new TokenIdentifierValue(Buffer.from(gameToken!)))
    .addArg(new AddressValue(new Address(address)))
    .build()
    .valueOf().toString()

  return new Transaction({
    receiver: new Address("erd1qqqqqqqqqqqqqqqpqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqzllls8a5w6u"),
    value: Balance.egld('0'),
    gasLimit: new GasLimit(60000000),
    data: new TransactionPayload(payload)
  });
}


export const setKeyTokenIdentifier = (identifier: string, nonce: number) => {
  let payload = TransactionPayload.contractCall()
    .setFunction(new ContractFunction("setKeyIdentifier"))
    .addArg(new TokenIdentifierValue(Buffer.from(identifier!)))
    .addArg(new U64Value(new BigNumber(nonce)))
    .build()
    .valueOf().toString()

  return new Transaction({
    receiver: new Address(storeSmartContractAddress),
    value: Balance.egld('0'),
    gasLimit: new GasLimit(60000000),
    data: new TransactionPayload(payload)
  });
}


export const createChest = (data: CreateChestData) => {
  let payload = TransactionPayload.contractCall()
    .setFunction(new ContractFunction("ESDTNFTTransfer"))
    .addArg(new TokenIdentifierValue(Buffer.from(data.token_identifier)))
    .addArg(new U64Value(new BigNumber(data.nonce)),)
    .addArg(new BigUIntValue(new BigNumber(data.amount)),)
    .addArg(new AddressValue(new Address(storeSmartContractAddress)))
    .addArg(BytesValue.fromUTF8("createChest"))
    .addArg(new U64Value(new BigNumber(data.probability_range)))
    .addArg(new BigUIntValue(new BigNumber(data.max_win_per_user)))
    .build()
    .valueOf().toString()

  return new Transaction({
    receiver: new Address(data.senderAddress),
    value: Balance.egld('0'),
    gasLimit: new GasLimit(60000000),
    data: new TransactionPayload(payload)
  });
}


export const createSwapTransaction = (sender: string, timestamp : number, payments: any[], rewards: any[]) => {
  let payload = TransactionPayload.contractCall()
    .setFunction(new ContractFunction("MultiESDTNFTTransfer"))
    .addArg(new AddressValue(new Address(storeSmartContractAddress)))
    .addArg(new BigUIntValue(new BigNumber(rewards.length)))
    
    rewards.forEach(reward_token => {
      payload = payload.addArg(new TokenIdentifierValue(Buffer.from(reward_token.ticker)))
        .addArg(new U64Value(new BigNumber(reward_token.nonce || 0)),)
        .addArg(new BigUIntValue(new BigNumber(reward_token.quantity)),)
    })

    payload.addArg(BytesValue.fromUTF8("createSwapTokens"))
    .addArg(new U64Value(new BigNumber(timestamp)))
    
    payments.forEach(payment_token => {
      payload = payload.addArg(new TokenIdentifierValue(Buffer.from(payment_token.ticker)))
        .addArg(new U64Value(new BigNumber(payment_token.nonce || 0)),)
        .addArg(new BigUIntValue(new BigNumber(payment_token.quantity)),)
    })

  const build = payload.build()
    .valueOf().toString();

  return new Transaction({
    receiver: new Address(sender),
    value: Balance.egld('0'),
    gasLimit: new GasLimit(2_100_000 * (rewards.length + payments.length)),
    data: new TransactionPayload(build) 
  });

}