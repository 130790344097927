import React, { useEffect, useState, useRef } from 'react';
import * as Dapp from '@elrondnetwork/dapp';
import { Pane, Heading, Spinner, Text, Link, Button, TextInputField } from 'evergreen-ui';
import { useMediaQuery } from 'react-responsive';
import { getNfts } from '../../apiEndpoints';
import { addToStore, burnToken } from '../../transactions';
const NftListTab = () => {
  const { address, explorerAddress } = Dapp.useContext();
  const [nfts, setNftsList] = useState([]);
  const [pending, setPending] = useState(false);
  const sendTransaction = Dapp.useSendTransaction();
  const smallRes = useMediaQuery({
    query: '(max-width: 860px)',
  });

  const mounted = useRef(true);

  const burnESDT = (nft: any) => {
    const tx = burnToken({
      tokenIdentifier: nft.ticker,
      quantity: nft.balance,
      nonce: nft.nonce,
      senderAddress: address
    });

    sendTransaction({
      transaction: tx,
      callbackRoute: '/dashboard',
    });
  }


  const updateSFTData = (value: any, index: number, key: string) => {
    let items: any = [...nfts];
    let item = { ...(items[index] as any) };
    item[key] = value;
    items[index] = item;
    setNftsList(items);
  }

  const addToStoreT = (nft: any) => {
    const tx = addToStore({
      senderAddress: address,
      quantity: nft.shop_quantity,
      token_identifier: nft.ticker,
      nonce: nft.nonce,
      price: nft.price,
      max_per_wallet: nft.max_per_wallet
    });

    sendTransaction({
      transaction: tx,
      callbackRoute: '/dashboard'
    }
    )
  }

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    setPending(true);
    const fetchNfts = async () => {
      const response = await fetch(getNfts(address), { signal });
      const data = await response.json();
      if (mounted.current) {
        setNftsList(data);
        setPending(false);
      }
    };
    if (address) {
      fetchNfts();
    }
    return () => {
      mounted.current = false;
    };
  }, [address]);

  return (
    <Pane marginBottom={25}>
      <Pane marginBottom={30} marginTop={30}>
        <Heading size={700}>Mes NFTs:</Heading>
      </Pane>
      <Pane display="flex" flexWrap="wrap">
        {pending ? (
          <Spinner marginX="auto" marginY="auto" />
        ) : (
          nfts.map((nft: any, index: number) => (
            <Pane
              backgroundColor="white"
              width={smallRes ? 'calc(50% - 20px)' : 'calc(25% - 20px)'}
              margin={10}
              padding={20}
              elevation={1}
              key={index}
            >
              <Heading>{nft.name}</Heading>
              <Pane>
                <Text fontSize={12}>Collection: </Text>
                <Link href={`${explorerAddress}collections/${nft.collection}`}>
                  <Text
                    color="gray800"
                    fontSize={12}
                    textDecoration="underline"
                  >
                    {nft.collection}
                  </Text>
                </Link>
              </Pane>
              <Pane>
                <Text fontSize={12}>Quantité restante: </Text>
                <Text
                  color="gray800"
                  fontSize={12}
                  fontWeight="bold"
                >
                  {nft.balance}
                </Text>
              </Pane>
              <Pane marginBottom={10}>
                <Text fontSize={12}>Id: </Text>
                <Link href={`${explorerAddress}nfts/${nft.identifier}`}>
                  <Text
                    color="gray800"
                    fontSize={12}
                    textDecoration="underline"
                  >
                    {nft.identifier}
                  </Text>
                </Link>
              </Pane>
              <TextInputField
                name="quantity"
                placeholder="5"
                label="Prix"
                description="Prix d'achat du SFT dans le shop en $SPACIUM"
                value={nft.price || 0}
                onChange={(e: any) => updateSFTData(parseInt(e.target.value) || 0, index, "price")}
              />

              <TextInputField
                name="quantity"
                placeholder="5"
                label="Quantité"
                description="Quantité à mettre dans le shop"
                value={nft.shop_quantity || 0}
                onChange={(e: any) => updateSFTData(parseInt(e.target.value) || 0, index, "shop_quantity")}
              />

              <TextInputField
                name="max_per_wallet"
                placeholder="5"
                label="Maximum par wallet"
                value={nft.max_per_wallet || 0}
                onChange={(e: any) => updateSFTData(parseInt(e.target.value) || 0, index, "max_per_wallet")}
              />
              <Button onClick={() => addToStoreT(nft)}>Ajouter au store</Button>
              {nft.uris?.[0] && (
                <Pane is="a" href={nft.media[0].thumbnailUrl}>
                  <Pane
                    is="img"
                    src={nft.media[0].thumbnailUrl}
                    maxWidth="100%"
                    width="auto"
                  />
                </Pane>
              )}
              <Button onClick={() => burnESDT(nft)} style={{ display: "none" }}>Burn</Button>


            </Pane>
          ))
        )}
        {!pending && nfts.length === 0 && <Text>Aucun SFT de publier pour le moment.</Text>}
      </Pane>
    </Pane>
  );
};

export default NftListTab;
